<template>
  <div class="wrapper">
    <div class="container">
      <div class="section__line">
        <div class="link__top">
          <a class="link__back pointer" @click="$router.push('/')">
            <span class="link__back--img">
              <img src="../../../assets/img/arrow-back.svg" />
            </span>
            Назад
          </a>
        </div>
      </div>

      <div class="section__bg section--table section__line--table">
        <div class="row">
          <div class="col-lg-6 col__padding">
            <h2>№ {{ contract.id }}</h2>
            <h3 v-if="contract.type">{{ contract.type.full_name_ru }}</h3>
          </div>
        </div>

        <div>
          <!-- <button @click="createAttachment(1)" class="sign__button mr-2 pointer">
            <i class="mdi mdi-check-circle-outline mr-1"></i>
            Создать приложение №1
          </button> -->
          <button @click="createAttachment(2)" class="sign__button mr-2 pointer" >
            <i class="mdi mdi-check-circle-outline mr-1"></i>
            Создать приложение №2
          </button>
          <button @click="createAttachment(3)" class="sign__button mr-2 pointer" 
					>
            <i class="mdi mdi-check-circle-outline mr-1"></i>
            Создать приложение №3
          </button>
          <button @click="createAttachment(4)" class="sign__button pointer">
            <i class="mdi mdi-check-circle-outline mr-1"></i>
            Создать приложение №4
          </button>
        </div>

		
        <div class="row mt-10">
          <Comments
            v-if="contract"
            :commentTypeId="$parent.commentTypeId"
            :messageType="$parent.commentType"
            :contract="contract"
            :attachment="$parent.attachment"
            :type="'user'"
          />
          <div
            class="word__contract col-lg-8"
            id="pdf word__contract"
            ref="document"
            v-html="document"
          ></div>
        </div>

        <div class="mt-4">
          <p v-if="contract.files && contract.files.length != 0">Скачать файлы:</p>
          <div class="item__row">
            <div
              class="item__column organization__files"
              v-for="file in contract.files"
              :key="file.id"
            >
              <div
                class="item__column mr-2 pointer"
                @click="downloadContractFile(file.id, file.file_path)"
              >
                <i class="mdi mdi-file-document-multiple-outline"></i>
                <p>{{ file.file_path }}</p>
              </div>

              <div class="item__row item__ac pointer" @click="deleteFile(file.id)">
                <i class="mdi mdi-trash-can-outline"></i>
                <p class="mb-0">Удалить</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="contract__bottom__info item__column mr-2 mb-4">
            <div class="item__row item__ac pointer" @click="showDocument(contract.id)">
              <i class="mdi mdi-eye mr-2"></i>
              Посмотреть договор
            </div>

            <div
              class="item__row item__ac pointer"
              @click="
                $router.push('/uc/update/contract/' + contract.id + '?type=contract')
              "
              v-if="contract.stage && contract.stage.name == 'formation'"
            >
              <i class="mdi mdi-pencil mr-2"></i>
              Редактировать
            </div>

            <p v-if="contract.stage">
              Статус: <span>{{ contract.stage.title }}</span>
            </p>

            <button
              @click="handleSend()"
              class="sign__button"
              v-if="
                contract.stage.name == 'accepted' &&
                GET_USER_DATA.can_sign &&
                GET_USER_DATA.role.name == 'CEO'
              "
            >
              <i class="mdi mdi-check-circle-outline mr-1"></i>
              Подписать
            </button>
          </div>

          <div
            class="contract__bottom__info item__column mr-2 mb-4"
            v-for="(attach, attach_index) in contract.attachments"
            :key="attach.id"
          >
            <div
              class="item__row item__ac pointer"
              @click="showAttachment(attach, attach.id)"
            >
              <i class="mdi mdi-eye mr-2"></i>
              <p class="mb-0">
                Посмотреть приложение №{{ attach.type.name.substring(11, 12) }}
              </p>
            </div>

            <p v-if="attach.stage">
              Статус: <span>{{ attach.stage.title }}</span>
            </p>

            <div
              class="item__row item__ac pointer"
              v-if="(attach.stage && attach.stage.name == 'formation') || !attach.stage"
              @click="
                $router.push(
                  '/uc/update/' +
                    contract.id +
                    '/' +
                    attach.id +
                    '?type=' +
                    attach.type.name.substring(11, 12)
                )
              "
            >
              <i class="mdi mdi-pencil mr-2"></i>
              Редактировать
            </div>

            <button
              @click="handleSend(attach_index, 'attachment')"
              class="sign__button pointer"
              v-if="
                attach.stage &&
                attach.stage.name == 'accepted' &&
                GET_USER_DATA.role.name == 'CEO'
              "
            >
              <i class="mdi mdi-check-circle-outline mr-1"></i>
              Подписать
            </button>

            <button
              @click="handleSend(attach_index, 'attachment')"
              class="sign__button pointer"
              v-if="
                attach.stage &&
                attach.stage.name == 'signing_ceo' &&
                GET_USER_DATA.role.name == 'CEO'
              "
            >
              <i class="mdi mdi-check-circle-outline mr-1"></i>
              Подписать
            </button>

            <button
              @click="handleSend(attach_index, 'attachment')"
              class="sign__button pointer"
              v-if="
                !attach.client_second_cms &&
                attach.stage &&
                GET_USER_DATA.role.name != 'CEO' &&
                attach.responsible_id == GET_USER_DATA.id
              "
            >
              <i class="mdi mdi-check-circle-outline mr-1"></i>
              Подпись агента
            </button>

            <p>Дата создания: {{ formatDate(attach.created_at) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import Comments from "../../../components/Comments";
export default {
  components: {
    Comments,
  },
  data() {
    return {
      contract: {
        type: "",
        stage: "",
      },
      document: "",
      ready: "",
      esp__array: [],
      attachment: null,
    };
  },

  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };
    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);

      console.log(arr);
      const parse__arr = arr;
      this.esp__array = parse__arr;
      if (typeof this.esp__array !== "undefined" && this.esp__array.code == 200) {
        this.signDocument(this.esp__array.responseObject, this.typeSign);
      }
    };
  },
  methods: {
    handleSend(index = null, type = null) {
      let folder = this.contract.base_64;
      if (type == "attachment") {
        this.typeSign = "attachment";
        folder = this.contract.attachments[index].base_64;
        this.currentAttachIndex = this.contract.attachments[index].id;
        this.attachment = this.contract.attachments[index];
      }
      if (this.ready == false) {
        this.showError = true;
      } else {
        this.showError = false;
        var createCAdESFromBase64Hash = {
          module: "kz.gov.pki.knca.commonUtils",
          method: "createCAdESFromBase64",
          args: ["PKCS12", "SIGNATURE", folder, true],
        };
        this.connection.send(JSON.stringify(createCAdESFromBase64Hash));
      }
    },
    formatDate(date) {
      date = date.split("T")[0].split("-");
      return date[2] + "." + date[1] + "." + date[0];
    },
    createAttachment(type) {
      this.$router.push("/uc/create/attachment/" + this.contract.id + "?type=" + type);
    },
    ...mapActions(["USER_DATA", "REDIRECT_FALSE"]),
    createFastiSecondAttachment(id) {
      this.$router.push("/contract/fasti/attachment/" + id);
    },
    deleteFile(id) {
      this.$axios({
        method: "delete",
        url: this.$API_URL + this.$API_VERSION + "user/delete/file?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        // responseType: 'arraybuffer'
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.getDocument(this.contract.id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadContractFile(id, name) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "document/file?id=" + id,
        responseType: "arraybuffer",
        data: this.sobs,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    signDocument(cms) {
      let url =
        "user/documents/" + this.contract.type.name + "/" + this.contract.id + "/sign";
      if (this.typeSign == "attachment") {
        url =
          "user/documents/" +
          this.contract.type.name +
          "/" +
          this.contract.id +
          "/" +
          this.currentAttachIndex +
          "/sign";
      }
      this.$axios({
        method: "patch",
        url: this.$API_URL + this.$API_VERSION + url,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          document_cms: cms,
          type:
            this.attachment &&
            this.attachment.responsible_id &&
            this.attachment.responsible_id == this.GET_USER_DATA.id
              ? "employee"
              : null,
        },
      })
        .then((response) => {
          if (response.data) {
            this.$toast.open({
              message: "Документ успешно подписан",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
          this.showDocument(this.contract.id);
          this.getDocument(this.contract.id);
          this.attachment = null;
        })
        .catch((error) => {
          console.warn(error);
          this.$toast.open({
            message: "Невалидный эцп",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },
    getDocument(id) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "user/document?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.contract = response.data;
          this.showDocument(id);
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    showDocument(id) {
      this.$parent.commentType = "contract";
      this.$parent.commentTypeId = id;
      this.$axios({
        method: "get",
        url:
          this.$API_URL +
          this.$API_VERSION +
          "user/documents/" +
          this.contract.type.name +
          "/show?id=" +
          id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    showAttachment(attachment, id) {
      this.$parent.commentType = "attachment";
      this.$parent.commentTypeId = id;
      this.$parent.attachment = attachment;
      this.$axios({
        method: "get",
        url:
          this.$API_URL +
          this.$API_VERSION +
          "user/documents/" +
          this.contract.type.name +
          "/attachment/show?id=" +
          id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {
    this.getDocument(this.$route.params.id);
  },
  watch: {},
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>

<style></style>
